import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { useDispatch, useSelector } from 'react-redux';
import {
    convertToBill,
    convertToBillWithDate,
    createPurchaseOrder,
    fetchPurchaseOrder,
    fetchPurchaseOrders,
    getPurchaseOrder,
    updatePurchaseOrder,
    updatePurchaseOrderQuantity,
} from '../../app/reducers/PurchaseOrder/purchaseOrderSlice';
import { ClipLoader } from 'react-spinners';
import axios from 'axios';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import moment from 'moment';
import { customIDs } from '../../utils/customIds';

const SplitModal = ({ setSplitModal, viewSplitModal, title, data }) => {
    const dispatch = useDispatch();
    const [current, setCurrent] = useState(1);
    const [heading, setHeading] = useState('Edit Purchase Order');
    const [loading, setLoading] = useState(false);
    const [remainingTaka, setRemainingTaka] = useState(null);

    const [timeoutLoader, setTimeoutLoader] = useState(false);
    const { purchaseOrderLoading } = useSelector(getPurchaseOrder);
    const organization_id = 637857442;

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: data.purchaseorder_id,
            meter: 1,
            taka: 1,
            quantity: '',
            qty_taka: '',
            current_qty_taka: '',
            current_quantity: '',
            initialData: {},
        },
        onSubmit: async (values, { resetForm }) => {
            let currentQuarter = moment().quarter();
            let upData = {
                id: values.id,
                line_items: [
                    {
                        ...values.initialData.line_items[0],
                        rate:
                            (formik.values.initialData.line_items[0]
                                .item_custom_fields[1].value *
                                values.qty_taka *
                                formik.values.initialData.line_items[0]
                                    .item_custom_fields[2].value) /
                            100,
                        item_custom_fields: [
                            ...values.initialData.line_items[0]
                                .item_custom_fields,
                            {
                                customfield_id:
                                    customIDs.purchase_item_qty_taka,
                                value: values.qty_taka,
                            },
                        ],
                        quantity: values.quantity,
                    },
                ],
                custom_fields: [
                    ...values.initialData.custom_fields,
                    {
                        customfield_id: customIDs.purchase_custom_qty_taka,
                        value: values.qty_taka,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_split,
                        value: 'true',
                    },
                    {
                        customfield_id:
                            customIDs.purchase_custom_system_generated,
                        value: 'Yes',
                    },
                ],
                date: moment()
                    .quarter(currentQuarter - 1)
                    .endOf('quarter')
                    .format('YYYY-MM-DD'),
                delivery_date: moment()
                    .quarter(currentQuarter - 1)
                    .endOf('quarter')
                    .format('YYYY-MM-DD'),
            };
            if (
                values.qty_taka != values.current_qty_taka &&
                values.quantity != 105
            ) {
                await dispatch(updatePurchaseOrderQuantity(upData));
                // await waModified(data);
                setTimeoutLoader(true);
                await new Promise(async (resolve) => {
                    await dispatch(
                        convertToBillWithDate({
                            id: values.id,
                            date: moment()
                                .quarter(currentQuarter - 1)
                                .endOf('quarter')
                                .format('YYYY-MM-DD'),
                        })
                    );
                    setTimeoutLoader(false);
                    resolve();
                });
            } else if (values.qty_taka != values.current_qty_taka) {
                await dispatch(updatePurchaseOrderQuantity(upData));
                // await waModified(data);
            } else if (values.quantity != 105) {
                await dispatch(updatePurchaseOrderQuantity(upData));
                setTimeoutLoader(true);
                new Promise(async (resolve) => {
                    await dispatch(
                        convertToBillWithDate({
                            id: values.id,
                            date: moment()
                                .quarter(currentQuarter - 1)
                                .endOf('quarter')
                                .format('YYYY-MM-DD'),
                        })
                    );
                    setTimeoutLoader(false);
                    resolve();
                });
            }
            setHeading('Create Purchase Order');
            setRemainingTaka(
                formik.values.initialData.line_items[0].item_custom_fields[0]
                    .value - values.qty_taka
            );
            formik2.setFieldValue('oldPoNumber', data.purchaseorder_number);
            setCurrent(2);
        },
    });

    const formik2 = useFormik({
        enableReinitialize: true,
        initialValues: {
            quantity: '',
            meter: 1,
            taka: 1,
            qty_taka: '',
            oldPoNumber: '',
        },
        validationSchema: Yup.object({
            quantity: Yup.number().required(),
            qty_taka: Yup.number().required(),
            meter: Yup.number(),
            taka: Yup.number(),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log(
                formik.values.initialData.line_items[0].item_custom_fields[0]
                    .value - values.qty_taka
            );
            setLoading(true);
            let currentQuarter = moment().quarter();
            let createData = {
                vendor_id: formik.values.initialData.vendor_id,
                payment_terms: parseInt(
                    formik.values.initialData.payment_terms
                ),
                payment_terms_label:
                    formik.values.initialData.payment_terms_label,
                source_of_supply: 'GJ',
                destination_of_supply: 'GJ',
                date: moment()
                    .quarter(currentQuarter)
                    .startOf('quarter')
                    .format('YYYY-MM-DD'),
                delivery_date: moment().add(12, 'days').format('YYYY-MM-DD'),
                delivery_customer_id:
                    formik.values.initialData.delivery_customer_id,
                line_items: [
                    {
                        description:
                            formik.values.initialData.line_items[0].description,
                        account_id: customIDs.purchase_item_account_id,
                        rate:
                            (formik.values.initialData.line_items[0]
                                .item_custom_fields[1].value *
                                values.qty_taka *
                                formik.values.initialData.line_items[0]
                                    .item_custom_fields[2].value) /
                            100,
                        quantity: values.quantity,
                        item_custom_fields: [
                            {
                                customfield_id:
                                    customIDs.purchase_item_qty_taka,
                                value: values.qty_taka,
                            },
                            {
                                customfield_id:
                                    customIDs.purchase_item_unit_rate,
                                value: formik.values.initialData.line_items[0]
                                    .item_custom_fields[1].value,
                            },
                            {
                                customfield_id:
                                    customIDs.purchase_item_commission,
                                value: formik.values.initialData.line_items[0]
                                    .item_custom_fields[2].value,
                            },
                            {
                                customfield_id:
                                    customIDs.purchase_item_vendorName,
                                value: formik.values.initialData.line_items[0]
                                    .item_custom_fields[3].value,
                            },
                        ],
                    },
                ],
                custom_fields: [
                    {
                        customfield_id: customIDs.purchase_custom_buyerName,
                        value: formik.values.initialData.custom_fields[0].value,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_qty_taka,
                        value: values.qty_taka,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_quality,
                        value: formik.values.initialData.custom_fields[2].value,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_rate,
                        value: formik.values.initialData.custom_fields[3].value,
                    },
                    {
                        customfield_id: customIDs.purchase_custom_split,
                        value: 'true',
                    },
                    {
                        customfield_id: customIDs.purchase_custom_payment_terms,
                        value: parseInt(
                            formik.values.initialData.payment_terms
                        ),
                    },
                    {
                        customfield_id:
                            customIDs.purchase_custom_payment_terms_2,
                        value: formik.values.initialData.payment_terms_label,
                    },
                    {
                        customfield_id: customIDs.purchase_split_po_reference,
                        value: values.oldPoNumber,
                    },
                    {
                        customfield_id:
                            customIDs.purchase_custom_system_generated,
                        value: 'Yes',
                    },
                ],
            };
            const purchaseOrder = await dispatch(
                createPurchaseOrder(createData)
            );

            if (purchaseOrder?.payload) {
                let updtData = await dispatch(
                    updatePurchaseOrder({
                        id: purchaseOrder?.payload?.purchaseorder
                            .purchaseorder_id,
                        status: 'open',
                    })
                );
                const oldUpdateData = {
                    id: data.purchaseorder_id,
                    custom_fields: [
                        {
                            customfield_id:
                                customIDs.purchase_split_po_reference,
                            value: purchaseOrder?.payload?.purchaseorder
                                .purchaseorder_number,
                        },
                    ],
                };
                await dispatch(updatePurchaseOrderQuantity(oldUpdateData));
                if (updtData?.payload) {
                    if (values.quantity != 105) {
                        setTimeoutLoader(true);
                        await new Promise(async (resolve) => {
                            await dispatch(
                                convertToBillWithDate({
                                    id: purchaseOrder?.payload?.purchaseorder
                                        .purchaseorder_id,
                                    date: moment()
                                        .quarter(currentQuarter)
                                        .startOf('quarter')
                                        .format('YYYY-MM-DD'),
                                })
                            );
                            setTimeoutLoader(false);
                            resolve();
                        });
                    }
                }
            }
            await dispatch(
                fetchPurchaseOrders({
                    page: data.page,
                    status: 'open',
                    date_start: moment()
                        .quarter(currentQuarter - 1)
                        .startOf('quarter')
                        .format('YYYY-MM-DD'),
                    date_end: moment()
                        .quarter(currentQuarter - 1)
                        .endOf('quarter')
                        .format('YYYY-MM-DD'),
                })
            );
            setLoading(false);
            setRemainingTaka(null);
            setCurrent(1);
            setHeading('Edit Purchase Order');
            setSplitModal(false);
        },
    });

    useEffect(async () => {
        if (data.purchaseorder_id) {
            const action = await dispatch(
                fetchPurchaseOrder({ id: data.purchaseorder_id })
            );
            if (action.payload.purchaseorder) {
                formik.setFieldValue(
                    'quantity',
                    action.payload.purchaseorder.total_quantity
                );
                formik.setFieldValue(
                    'current_quantity',
                    action.payload.purchaseorder.total_quantity
                );
                formik.setFieldValue(
                    'qty_taka',
                    action.payload.purchaseorder.custom_fields[1].value
                );
                formik.setFieldValue(
                    'current_qty_taka',
                    action.payload.purchaseorder.custom_fields[1].value
                );
                formik.setFieldValue(
                    'initialData',
                    action.payload.purchaseorder
                );
            }
        }
    }, [data.purchaseorder_id]);

    const waModified = async (row) => {
        // const action = await dispatch(fetchPurchaseOrder({id:row.purchaseorder_id}))
        // if(action.payload?.purchaseorder) {
        const vendorCheck = await axios.get(
            `https://app2.com.bot/webhook/641a5e12a52b8e34db70b32c?contact_type=vendor&po_id=${formik.values.initialData.purchaseorder_id}&org_id=${organization_id}&contact_id=${formik.values.initialData.vendor_id}&delivery_date=${formik.values.initialData.delivery_date}`
        );
        if (vendorCheck.data?.accepted) {
            toast.success('Vendor True');
        } else {
            toast.error('Not accepted try again');
        }
        const customerCheck = await axios.get(
            `https://app2.com.bot/webhook/641a5e12a52b8e34db70b32c?contact_type=customer&po_id=${formik.values.initialData.purchaseorder_id}&org_id=${organization_id}&contact_id=${formik.values.initialData.delivery_customer_id}&delivery_date=${formik.values.initialData.delivery_date}`
        );
        if (customerCheck.data?.accepted) {
            toast.success('Customer True');
        } else {
            toast.error('Not accepted try again');
        }
        // }else{
        //   toast.error("Something Went Wrong.Please try again")
        // }
    };

    return (
        <ModalBasic
            title={heading}
            modalOpen={viewSplitModal}
            setModalOpen={setSplitModal}
        >
            {current == 1 &&
                (purchaseOrderLoading || timeoutLoader ? (
                    <ClipLoader />
                ) : (
                    <form
                        className="flex flex-col gap-4 p-4"
                        onSubmit={formik.handleSubmit}
                    >
                        <FormikInputGroup
                            label="PO Number"
                            formik={formik}
                            type="number"
                            name="id"
                            readOnly
                        />
                        <div className="flex gap-5">
                            <FormikInputGroup
                                label="Meter"
                                formik={formik}
                                type="number"
                                name="meter"
                            />
                            <FormikInputGroup
                                label="Taka"
                                formik={formik}
                                type="number"
                                name="taka"
                            />
                            <p className="self-center">
                                Quantity:-{' '}
                                <span
                                    className="font-bold cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        formik.setFieldValue(
                                            'quantity',
                                            formik.values.taka === 0
                                                ? 0
                                                : formik.values.meter /
                                                      formik.values.taka
                                        );
                                    }}
                                >
                                    {formik.values.taka === 0
                                        ? 0
                                        : formik.values.meter /
                                          formik.values.taka}
                                </span>
                            </p>
                        </div>
                        <FormikInputGroup
                            label="Quantity Meter"
                            formik={formik}
                            type="number"
                            name="quantity"
                            autoFocus
                        />
                        <FormikInputGroup
                            label="Quantity Taka"
                            formik={formik}
                            type="number"
                            name="qty_taka"
                        />
                        <div>
                            <PrimaryButton type="submit">Submit</PrimaryButton>
                        </div>
                    </form>
                ))}
            {loading || timeoutLoader ? (
                <ClipLoader />
            ) : (
                current == 2 && (
                    <div>
                        <form
                            className="flex flex-col gap-4 p-4"
                            onSubmit={formik2.handleSubmit}
                        >
                            <FormikInputGroup
                                label="Old PO Number"
                                formik={formik2}
                                type="string"
                                name="oldPoNumber"
                                readOnly
                            />
                            <div className="flex gap-5">
                                <FormikInputGroup
                                    label="Meter"
                                    formik={formik2}
                                    type="number"
                                    name="meter"
                                />
                                <FormikInputGroup
                                    label="Taka"
                                    formik={formik2}
                                    type="number"
                                    name="taka"
                                />
                                <p className="self-center">
                                    Quantity:-{' '}
                                    <span
                                        className="font-bold cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            formik2.setFieldValue(
                                                'quantity',
                                                formik2.values.taka === 0
                                                    ? 0
                                                    : formik2.values.meter /
                                                          formik2.values.taka
                                            );
                                        }}
                                    >
                                        {formik2.values.taka === 0
                                            ? 0
                                            : formik2.values.meter /
                                              formik2.values.taka}
                                    </span>
                                </p>
                            </div>
                            <FormikInputGroup
                                label="Quantity Meter"
                                formik={formik2}
                                type="number"
                                name="quantity"
                                autoFocus
                            />
                            <div>
                                Remaining taka :-{' '}
                                <span className="font-bold">
                                    {remainingTaka || 'Not available'}
                                </span>
                            </div>
                            <FormikInputGroup
                                label="Quantity Taka"
                                formik={formik2}
                                type="number"
                                name="qty_taka"
                            />
                            <div>
                                <PrimaryButton type="submit">
                                    Submit
                                </PrimaryButton>
                            </div>
                        </form>
                    </div>
                )
            )}
        </ModalBasic>
    );
};

export default SplitModal;
