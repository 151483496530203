export const customIDs = {
    customer_calling: '466597000018024126',
    customer_chalan_name: '466597000012835199',
    customer_chalan_name1: '466597000018046172',
    customer_title: '466597000008642679',
    customer_priority: '466597000014141047',
    customer_manager_mobile_number: '466597000001606373',
    customer_yarn_grade: '466597000017215024',
    customer_birthday: '466597000001880211',
    customer_update_date: '466597000022940501',
    customer_quarterly_brokerage_mode: '466597000018369333',
    customer_group_wise_billing_mode: '466597000018369333',
    invoice_tracking_id: '466597000001103138',
    invoice_rate_calculation: '466597000001686021',
    invoice_statment_period: '466597000002241334',
    invoice_chalan_number: '466597000014434058',
    invoice_mobile_number: '466597000019575086',
    invoice_po_report_url: '466597000018341807',
    invoice_bill_report_url: '466597000018341811',
    invoice_tds_tax_id: '466597000030620001',
    invoice_item_account_id: '466597000000000388',
    invoice_item_id: '466597000000387023',
    purchase_item_account_id: '466597000000034003',
    purchase_item_qty_taka: '466597000000103053',
    purchase_item_commission: '466597000000103033',
    purchase_item_unit_rate: '466597000000103094',
    purchase_item_vendorName: '466597000002198310',
    purchase_custom_buyerName: '466597000000182118',
    purchase_custom_qty_taka: '466597000000230112',
    purchase_custom_quality: '466597000000230124',
    purchase_custom_rate: '466597000000343686',
    purchase_custom_split: '466597000018369145',
    purchase_custom_payment_terms: '466597000018470329',
    purchase_custom_system_generated: '466597000030646520',
    purchase_custom_payment_terms_2: '466597000021570002',
    purchase_payment_complaint: '466597000001633235',
    purchase_custom_complaint_note: '466597000009113598',
    purchase_custom_tag_2: '466597000010871222',
    purchase_custom_solve_note: '466597000010871228',
    purchase_custom_complaint_open_date: '466597000018455482',
    purchase_custom_complaint_cloze_date: '466597000018455486',
    purchase_custom_vendor_commision: '466597000020257001',
    purchase_custom_has_complaint: '466597000018470325',
    purchase_bill_custom_customer_name: '466597000000103364',
    purchase_split_po_reference: '466597000021962217',
    purchase_custom_note: '466597000022526345',
    purchase_order_followup_task: '466597000023127273',
    purchase_order_followup_due_date: '466597000023127277',

    // purchase_custom_tag_3: "466597000011301196",
    // purchase_custom_note: "466597000011301547",
    // purchase_custom_po_total_outstanding: "466597000017175073",
    // purchase_custom_ven_com_reason: "466597000017293286",
    // purchase_custom_delivery_status: "466597000017387441",
    // purchase_custom_com_reson_no: "466597000017790035",
    // purchase_custom_copy_payment_terms: "466597000018470329",
    credit_q1: '466597000018455490',
    credit_q2: '466597000018455496',
    credit_q3: '466597000018455500',
    credit_q4: '466597000018455504',
};
